<template>
  <div class="share">
    <h1>请用微信好友邀请家人</h1>
    <div class="share-btn" @click="isShareDialogVisible = true">
        <div class="share-btn__l">
            <img src="@/accesst/index/icon_wechat.png" alt="微信邀请">
            <span>微信好友</span>
        </div>
        <van-icon class="share-btn__r" name="arrow" />
    </div>
    <van-overlay :show="isShareDialogVisible" @click="isShareDialogVisible = false">
        <div class="share-dialog" @click.stop>
            <img class="guide-img" src="@/accesst/index/share_guide.png" alt="">
            <div class="title">邀请好友加入你的家庭组</div>
            <div class="desc">点击右上角“...”分享给微信好友</div>
        </div>
    </van-overlay>  
  </div>
</template>
<script>
import { Icon, Overlay } from 'vant';    
import { wxJsApi } from '@/api/h5'
import { getShareToken } from '@/api/family'

export default {
    name: 'Share',
    components: {
        [Icon.name]: Icon,
        [Overlay.name]: Overlay
    },
    data() {
        return {
            isShareDialogVisible: false,
            familyName: ''
        }
    },
    methods: {
        setShareData(data) {
            function isIOS() {
                return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
            }
            let shareUrl;
            if (isIOS()) {
                shareUrl = sessionStorage.getItem('originUrl')
            }else {
                shareUrl = location.href
            }
            wxJsApi({url: encodeURIComponent(shareUrl) }).then(res => {
                this.$wxShare(res, {
                    title: `邀请你加入${this.familyName}`,
                    desc: '快来加入你的家庭组吧',
                    imgUrl: 'https://lbxcx.oss-cn-beijing.aliyuncs.com/images/share_img.jpg',
                    link: `${process.env.VUE_APP_H5_DOMAIN}/family/join?wxShareToken=${data.wxShareToken}&expireTime=${data.expireTime}`
                }, () => {
                })
            }).catch(err => {
                this.$toast(err.msg)
            })      
        },
        handleShareWechat() {
            getShareToken({
                role: this.$route.query.role,
                remarks: this.$route.query.remarks
            }).then(res => {
                this.setShareData(res)
            }).catch(err => {
                this.$toast(err.msg)
            })
        }
    },
    mounted() {
        this.handleShareWechat()
        const userInfo = localStorage.getItem('userInfo')
        if (userInfo) {
            this.familyName = JSON.parse(userInfo).familyName
        }
    }
}
</script>
<style lang="less" scoped>
.share {
    padding: .9rem .5rem;
    h1 {
        margin: 0;
        font-size: .56rem;
        color: #333;
        line-height: .66rem;
        font-weight: 500;
    }
    .share-btn {
        padding-top: .68rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .share-btn__l {
            display: flex;
            align-items: center;
            gap: .14rem;
            img {
                width: .72rem;
                height: .72rem;
            }
            span {
                font-size: .32rem;
                color: #333;
                line-height: .38rem;
            }
        }
        .share-btn__r {
            font-size: .32rem;
            color: #999;
        }
    }   
    .share-dialog {
        .guide-img {
            width: 2.4rem;
            height: 2.58rem;
            display: block;
            margin-left: 4.6rem;
        }
        .title {
            padding-top: .02rem;
            font-size: .48rem;
            line-height: .68rem;
            color: #fff;
            font-weight: 800;
            text-align: center;
        }
        .desc {
            padding-top: .48rem;
            font-size: .34rem;
            line-height: .48rem;
            color: #DDD9D9;
            font-weight: 500;
            text-align: center;
        }
    }
}
</style>    
